import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    a: {
      color: "var(--textLink)",
      boxShadow: "none"
    },
    // gatsby-remark-autolink-headers - don't underline when hidden
    "a.anchor": {
      boxShadow: "none",
    },
    // gatsby-remark-autolink-headers - use theme colours for the link icon
    'a.anchor svg[aria-hidden="true"]': {
      stroke: "var(--textLink)",
    },
    hr: {
      background: "var(--hr)",
    },
    h1: {
      fontFamily: "Noto Sans JP"
    },
    p: {
      letterSpacing: '0.08em',
    },
  }
}

Wordpress2016.googleFonts = [
  {
    name: 'M+PLUS+Rounded+1c',
    styles: ['400'],
  },
  {
    name: 'Noto+Sans+JP',
    styles: ['400'],
  },
];
Wordpress2016.headerFontFamily = [
  "M PLUS Rounded 1c",
  "sans-serif",
];
Wordpress2016.bodyFontFamily = [
  "Noto Sans JP",
  "sans-serif",
];
Wordpress2016.baseLineHeight = 2.0;
Wordpress2016.baseFontSize = '14px';

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
