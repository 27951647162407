import React from "react"

const Footer = () => {
  return (
    <footer className="my-12 text-center">
      © {new Date().getFullYear()},
      {` `}
      <a
        href="https://www.winc.ne.jp/"

      >
        WINC
      </a>
      .
    </footer>
  )
}

export default Footer
